<template>
    <div v-if="template_status">
        <!-- 我的 -->
        <div class="me grey-bg">
            <!--    我的头部开始-->
            <div class="user-header">
                <!-- <img class="user-bg" src="~assets/images/user/user-bj.png"/> -->
                <div class="user-con">
                    <div class="avatar-box">
                      <img class="user-portrait" :src="userInfo.avatar && userInfo.avatar ? userInfo.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" />
                      <img src="~assets/images/user/vip-icon.png" v-if="userInfo && userInfo.is_vip == 1" class="vip-icon" alt="">
                         <div class="user-nickname-grade align-center">
                                    <div>Lv{{ userInfo && userInfo.level }}</div>
                                </div>
                    </div>
                    <div class="user-info">
                        <div class="user-level">
                            <div class="user-nickname">
                                <div class="vertical-center">
                                  <div class="user-nickname-title  white-space">{{ userInfo.nickname ? userInfo.nickname : '酸谈社群' }}

                                  </div>
                                  <img class="icon" v-if="userInfo && userInfo.is_team_master && userInfo.is_team_master == 1"
                                       src="~assets/images/index/captain-icon.png"/>
                                </div>

                                  <div class="serial-number vertical-center" v-if="userInfo.number">编号:{{userInfo.number}}
                                    <img src="~assets/images/public/captain-s.png" v-if="userInfo.is_group_master"  class="captain-s" />
                                    <img :src="userInfo.user_label_picture" v-if="userInfo.user_label_picture"  class="labelimg">
                                    <img src="~assets/images/public/36ce.png" v-else class="labelimg">
                                  </div>
                             
                              
                            </div>
                            <div class="user-sign align-center" :class="receptions && receptions.is_sign ? 'immediately-active' : ''" @click="goLink('Sign')" v-if="receptions != null">
                                <div>{{receptions.is_sign ? '已签到' : '签到'}}</div>
                            </div>
                            <div class="user-btn">
                              <div class=""><img class="user-btn-item" src="~assets/images/user/setup.png"  @click="goLink('Setup')"/></div>
                               <div :class="userInfo.system_notification_count != 0 ? 'user-btn-item-a':''"><img  class="user-btn-item"  src="~assets/images/user/news.png"  @click="goLink('New')"/></div>
                                <div :class="userInfo.im_notification_count != 0 ? 'user-btn-item-a':''"><img class="user-btn-item"  src="~assets/images/user/letter.png"  @click="goNews()"/></div>
                        
                        
                        
                    </div>
                        </div>
                        <!-- <div class="user-grade">
                            <div class="user-grade-now">
                                <div>Lv{{ userInfo && userInfo.level }}</div>
                                <div v-if="userInfo.is_max === 0">Lv{{ userInfo && userInfo.next_level }}</div>
                            </div>
                            <div class="progress">
                                <van-progress v-if="userInfo.is_max === 1"
                                    :percentage="100"
                                    :show-pivot="false" color="#7F4A1B" stroke-width="7" border-radius="5"/>
                                <van-progress v-else
                                    :percentage="progress_experience"
                                    :show-pivot="false" color="#7F4A1B" stroke-width="7" border-radius="5"/>
                            </div>
                        </div> -->
                        <!-- <div class="user-grade-tip clear" v-if="userInfo.is_max === 0">差{{ userInfo && userInfo.next_level_difference }}经验值升级</div> -->
                    </div>
                </div>
                <div class="user-integral">
                    <text class="integral-history" @click="goHistory('credit')">历史积分：{{ $handle.tranNumber( userInfo.amount_credit,3) }}</text>
                    <text>|</text>
                    <text class="integral-now" @click="goHistory('credit')">当前积分：{{ $handle.tranNumber( userInfo.credit,3) }}</text>
                </div>
            </div>
            <!--    我的头部结束-->
            <div class="me-body">
                <!--    队长通知管理开始-->
                <div class="captain-notice" v-if="userInfo.is_team_master || userInfo.is_group_master">
                    <div class="notification">
                        <div class="notification-item align-center" @click="goLink('Task')">
                            <img class="me-body-icon" src="~assets/images/user/task-icon.png"/>
                            <div class="me-body-name-icon">任务通知</div>
                        </div>
                        <div class="notification-item align-center" @click="goLink('Notice')">
                            <img class="me-body-icon" src="~assets/images/user/team-icon.png"/>
                            <div class="me-body-name-icon">战队通知</div>
                        </div>
                    </div>
                </div>
                <!--    队长通知管理结束-->

                <!--    个人中心开始-->
                <div class="personal-center">
                    <div class="me-body-title">个人中心</div>
                    <div class="personal clear">
                        
                        <div class="me-body-item me-body-item-friend" @click="goLink('Friend')" v-if="userInfo && userInfo.is_join == 1 && (invite_env_status || userInfo.is_team_master == 1)">
                            <img class="me-body-icon" src="~assets/images/user/friend-icon.png"/>
                            <div class="me-body-name-icon">邀请好友</div>
                            <div class="invitation">
                                <img class="invitation-text" src="~assets/images/user/invitation.png"/>
                            </div>
                        </div>
                        <div class="me-body-item" @click="goLink('Stat')" v-if="userInfo && (userInfo.is_team_master == 1 || userInfo.is_group_master == 1)">
                          <img class="me-body-icon" src="~assets/images/user/team-stat-icon.png"/>
                          <div class="me-body-name-icon">队伍统计</div>
                        </div>
                        <div class="me-body-item"  @click="goLink('Team')" v-if="userInfo && (userInfo.is_team_master == 1 || userInfo.is_group_master == 1)">
                          <img class="me-body-icon" src="~assets/images/user/management-team-icon.png"/>
                          <div class="me-body-name-icon">小队管理</div>
                        </div>
                        <div class="me-body-item" @click="goLink('Group')">
                          <img class="me-body-icon" src="~assets/images/user/group-icon.png"/>
                          <div class="me-body-name-icon">我的小组</div>
                        </div>
                        <div class="me-body-item" @click="goLink('Grade')">
                            <img class="me-body-icon" src="~assets/images/user/grade-icon.png"/>
                            <div class="me-body-name-icon">等级中心</div>
                        </div>
                        <div class="me-body-item" @click="goLink('Record')">
                            <div :class="userInfo.order_count != 0 ? 'user-btn-item-a':''" style="display:flex;top:0">
                              <img class="me-body-icon" src="~assets/images/user/exchange-icon.png"/>
                            </div>
                            
                            <div class="me-body-name-icon">订单中心</div>
                        </div>
                        <div class="me-body-item" @click="goLink('Help')">
                            <img class="me-body-icon" src="~assets/images/user/help-icon.png"/>
                            <div class="me-body-name-icon">意见反馈</div>
                        </div>
                         <div class="me-body-item" @click="goHistory('credit')">
                            <img class="me-body-icon" src="~assets/images/user/history-icon.png"/>
                            <div class="me-body-name-icon">历史记录</div>
                        </div>
                        
                        <!-- <div class="me-body-item"  @click="goLink('Target')" v-if="userInfo && userInfo.is_join == 1">
                            <img class="me-body-icon" src="~assets/images/user/target-icon.png"/>
                            <div class="me-body-name-icon">我的目标</div>
                        </div>
                        <div class="me-body-item"  @click="goLink('Interest')" v-if="userInfo && userInfo.is_join == 1">
                            <img class="me-body-icon" src="~assets/images/user/interest-icon.png"/>
                            <div class="me-body-name-icon">我的兴趣</div>
                        </div> -->
                        
                        
                        
                    </div>
                </div>
                <!--    个人中心结束-->

                <!--    我的账号开始-->
                <div class="account-information">
                    <div class="me-body-title">社区中心</div>
                  <van-swipe class="my-swipe" :loop="false" show-indicators indicator-color="#F19149" height="100">
                    <van-swipe-item>
                      <div class="account">
                        <div class="me-body-item" @click="goLink('Release')">
                          <img class="me-body-img"    src="~assets/images/user/release.png" />
                          <div class="me-body-name-icon">我的发布</div>
                        </div>
                        <div class="me-body-item" @click="goLink('Sell')">
                          <img class="me-body-img"    src="~assets/images/user/sell.png" />
                          <div class="me-body-name-icon">我的出售</div>
                        </div>
                        <div class="me-body-item" @click="goLink('Download')">
                          <img class="me-body-img"    src="~assets/images/user/download.png" />
                          <div class="me-body-name-icon">我的下载</div>
                        </div>
                        <div class="me-body-item" @click="goLink('Collection')">
                          <img class="me-body-img"  src="~assets/images/user/collection.png"/>
                          <div class="me-body-name-icon">我的收藏</div>
                        </div>


                      </div>
                    </van-swipe-item>
                    <van-swipe-item>
                      <div class="account">
                        <div class="me-body-item" @click="goLink('Browse')">
                          <img class="me-body-img"  src="~assets/images/user/browse.png"  />
                          <div class="me-body-name-icon">我的浏览</div>
                        </div>
                      </div>
                    </van-swipe-item>
                  </van-swipe>
                </div>
            </div>
            <!--    我的头部结束-->
            <!-- <div class="log-out-box">
              <div class="log-out" @click="logOut">退出登录</div>
            </div> -->
        </div>
    </div>
</template>

<script>
import Avatar from 'vue-avatar'
export default {
    name: "me",
    data() {
        return {
            progress_experience: 0,
            invite_env_status: false,
            template_status: false,
            receptions: null, // 签到值
        }
    },
    created() {
        if(!this.$cache.getIsLogin()) {
          this.$store.commit('M_LOGIN_POP', true)
        }else {
          this.template_status= true
          // 判断地址缓存
          if (localStorage.getItem('address_f')) {
            localStorage.removeItem('address_f')
          }
          if(this.userInfo){
            if(parseFloat(this.userInfo.next_level_experience) == 0){
              this.progress_experience = 100;
            }else {
              let speed_num = (parseFloat(this.userInfo.experience) / parseFloat(this.userInfo.next_level_experience))*100;
              if(speed_num > 100){
                speed_num = 100
              }else if(speed_num < 0){
                speed_num = 0
              }
              this.progress_experience = speed_num
            }
          }

          // 判断非队长身份邀请
          if(process.env.VUE_APP_INVITE == 'true'){
            this.invite_env_status= true
          }
          this.getReceptions()
          this.$store.commit('M_ACT','1')
          this.$store.commit('M_ACTS','0')
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$util.domMinHeight()
        })
    },
    methods: {
        // 路由跳转
        goLink(type) {
          if(type == 'Grade') {
            // 等级中心
            this.$router.push('/user/level')
          }else if(type == 'Friend'){
            // 邀请好友
            window.location.href = window.location.origin + '/user/invite'+ '?invite_code='+ this.userInfo.invite_code
          }else if(type == 'Target'){
            if(!this.userInfo.has_chose) {
              this.$router.push('/')
              return false
            }
            // 我的目标
            this.$router.push('/user/target')
          }else if(type == 'Interest'){
            if(!this.userInfo.has_chose) {
              this.$router.push('/')
              return false
            }
            // 我的兴趣
            this.$router.push('/user/interest')
          }else if(type == 'Team'){
            // 小队管理
            if(this.userInfo && this.userInfo.is_team_master == 1){
              this.$router.push('/management-team')
            }else {
              this.$router.push('/management-team/show')
            }

          }else if(type == 'Stat'){
            // 队伍统计
            this.$router.push('/team/stat')
          }else if(type == 'Sign'){
            // 签到跳转
            this.$router.push('/task/sign')
          }else if(type == 'Task'){
            // 任务通知跳转
            this.$router.push('/user/task')
          }else if(type == 'Notice'){
            // 跳转战队通知
            this.$router.push('/user/notice')
          }else if(type == 'Address'){
            // 跳转收货地址
            this.$router.push('/user/address')
          }else if(type == 'Record'){
            // 跳转兑换记录
            this.$router.push('/user/record')
          }else if(type == 'Help'){
            // 跳转帮助与反馈
            this.$router.push('/user/help')
          }else if(type == 'About'){
            // 跳转关于我们
            this.$router.push('/user/about')
          }else if(type == 'Group'){
            // 跳转我的小组
            this.$router.push('/user/group')
          }else if(type == 'Release'){
            // 跳转我的发布
            this.$router.push('/user/release')
          }else if(type == 'Sell'){
            // 跳转我的出售
            this.$router.push('/user/sell')
          }else if(type == 'Collection'){
            // 跳转我的收藏
            this.$router.push('/user/collection')
          }else if(type == 'Browse'){
            // 跳转我的浏览
            this.$router.push('/user/browse')
          }else if(type == 'Setup'){
            // 跳转我的设置
            this.$router.push('/user/setup')
          }else if(type == 'New'){
            // 跳转我的消息
            this.$router.push('/user/new')
          }else if(type == 'Download'){
            // 跳转我的下载
            this.$router.push('/user/download')
          }
        },
        goNews(){
          this.$router.push('/user/im')
        },

        // 跳转历史记录
        goHistory(type) {
            this.$router.push({path: '/user/history', query: {type: type}})
        },

        // 退出登录
        logOut(){
          this.$toast('退出成功');
          this.$cache.removeLoginInfo();
          setTimeout(()=>{
            window.location.href= window.location.origin
          },1500)
        },

      //获取用户签到信息
      getReceptions(){
        let _this = this
        let url = _this.$api.Receptions + '?getTime=' + new Date().getTime()
        _this.$http.get(url,true).then(res => {
          if (res.data.success) {
            _this.receptions = res.data.data
          }
        })
      }

    },
    watch: {
        userInfo(val, oldVal) {
            return val;
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        }
    },
  components: {
    Avatar
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/me/me.scss";
.immediately-active{
  opacity: 0.8;
}
</style>
<style lang="scss">
.van-swipe__indicator{
  background: #B0B0B0;
}
.wx-swiper-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.van-swipe__indicator--active{
  width: 15px;
  height: 5px;
  border-radius: 5px;
}
</style>
